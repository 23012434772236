<template>
   <button
      :class="[
         btn ? `btn-${btn}` : 'btn',
         py ? `py-${py}` : 'py',
         px ? `px-${px}` : 'px',
         { 'bg-blue': bgBlue, 'bg-green': bgGreen, 'fw-bold': fwBold },
      ]"
      :style="{ height: height }"
      class="btn"
      type="button"
      @click="$emit('click')"
   >
      <component :is="icon"></component>
      <span :class="[ps ? `ps-${ps}` : 'ps', pe ? `pe-${pe}` : 'pe']">
         <slot></slot>
      </span>
      <component :is="iconRight"></component>
   </button>
</template>

<script>
export default {
   name: 'AmcButton',
   props: {
      icon: {
         type: [String, Object],
         default: '',
      },
      iconRight: {
         type: [String, Object],
         default: '',
      },
      ps: {
         type: [String, Number],
         default: 0,
      },
      pe: {
         type: [String, Number],
         default: 0,
      },
      py: {
         type: [String, Number],
         default: '',
      },
      px: {
         type: [String, Number],
         default: 0,
      },
      btn: {
         type: [String, Number],
         default: 'primary',
      },
      bgBlue: {
         type: Boolean,
         default: false,
      },
      bgGreen: {
         type: Boolean,
         default: false,
      },
      fwBold: {
         type: Boolean,
         default: false,
      },
      height: {
         type: [Number, String],
         default: '',
      },
   },
};
</script>

<style scoped>
.bg-blue {
   background-color: #e5efff;
   border: none !important;
   color: #137af6;
}
.bg-green {
   background-color: #d5eedc;
   border: none !important;
   color: #2fa84f;
}
.fw-bold {
   font-weight: 500;
}
</style>
